<template>
  <div
    v-if="form !== null"
    class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap container-fluid"
  >
    <div class="width-100">
      <buttons-form v-model="form" ref="notificationsButtonsForm" />

      <div class="justify-content-end d-flex bg-white tabs-footer">
        <button
          :disabled="disabledButtons"
          type="button"
          class="btn btn-success font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
          @click.prevent="save"
        >
          Сохранить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SET_BREADCRUMB,
  SET_BREADCRUMB_SHOW_BACK,
  SET_BREADCRUMB_ROUTE_BACK,
} from '@/core/services/store/breadcrumbs.module';
import { GET_ITEM_NOTIFICATION_SCRIPTS } from '@/core/services/store/notifications.module';
import ButtonsForm from '@/view/content/forms/notifications/buttons/ButtonsForm.vue';

export default {
  components: { ButtonsForm },

  data() {
    return {
      disabledButtons: false,
      form: null,
    };
  },

  async beforeMount() {
    const id = parseInt(this.$route.params?.id);
    this.$store.commit(SET_BREADCRUMB_SHOW_BACK, true);
    this.$store.commit(SET_BREADCRUMB_ROUTE_BACK, {
      name: 'notifications-buttons',
    });
    if (id) {
      this.form = await this.$store.dispatch(GET_ITEM_NOTIFICATION_SCRIPTS, id);
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Напоминалки', route: { name: 'notifications-buttons' } },
        { title: this.form?.Name },
      ]);
    } else {
      throw Error('Not id');
    }
  },

  methods: {
    save() {
      this.$refs?.notificationsButtonsForm?.submit();
    },
  },
};
</script>
